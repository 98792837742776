import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  CircularProgress,
  Tooltip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  TableContainer
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import { strings } from "../../localStrings";
import {
  DeleteQuery,
  GetRecentQueries
} from "../../requests/queryHistoryRequests";
import { executeQuery } from "../../utility/ExecuteQuery";
import { formatDate } from "../../utility/formatDate";
import BuildLabels from "./BuildLabels";
import { LoginContext } from "../../context/LoginContext";
import { requestError } from "../../utility/requestError";
import { SessionContext } from "../../context/SessionContext";
import { LanguageContext } from "../../context/LanguageContext";
import { LocationContext } from "../../context/LocationContext";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the recent queries of the current user.
 *
 * @param baskets The baskets of the current user.
 * @param sharedBaskets The baskets shared with the current user.
 * @returns {JSX.Element}
 * @constructor
 */
function RecentQueryView({ baskets, sharedBaskets }) {
  const classes = useStyles();

  const language = useContext(LanguageContext); // Rerender on language change

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionExpiredDispatcher = useContext(SessionContext)
    .sessionExpiredDispatcher;

  const locationContext = useContext(LocationContext);
  const baseUrl = window.location.href.replace(locationContext.pathname, "");

  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);

  const reloadQueries = useCallback(
    (load = true) => {
      setLoading(load);
      GetRecentQueries()
        .then(response => {
          setQueries(response.data.reverse());
          setLoading(false);
        })
        .catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));
    },
    [loginStateDispatcher, sessionExpiredDispatcher]
  );

  const deleteQuery = query => {
    DeleteQuery(query._id)
      .then(() => {
        reloadQueries(false);
      })
      .catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));
  };

  // Load recent queries.
  useEffect(() => {
    if (loginState) {
      reloadQueries();
    }
  }, [loginState, reloadQueries]);

  return (
    <Paper className={classes.paperTable}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h5" component="h2">
          {strings.recentQueries}
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{strings.searchDate}</TableCell>
              <TableCell align="center">{strings.searchTerm}</TableCell>
              <TableCell align="right">{strings.chosenFilters}</TableCell>
              <TableCell align="right">{strings.actions}</TableCell>
            </TableRow>
          </TableHead>
          {loading && (
            <TableBody>
              <TableRow key={0}>
                <TableCell component="th" scope="row" />
                <TableCell align="right">
                  <CircularProgress
                    className={classes.progress}
                    color="secondary"
                    disableShrink
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
            </TableBody>
          )}

          {!loading && (
            <TableBody>
              {queries.map(query => (
                <TableRow hover key={query._id}>
                  <TableCell component="th" scope="row">
                    {formatDate(query.date)}
                  </TableCell>
                  <TableCell align="center">
                    {typeof query.query.term === "string" && query.query.term}
                  </TableCell>
                  <TableCell align="right">
                    <BuildLabels
                      query={query.query}
                      baskets={baskets}
                      sharedBaskets={sharedBaskets}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title={strings.executeSaved}>
                      <IconButton
                        className={classes.button}
                        size={"small"}
                        onClick={() => {
                          return executeQuery(query.query, baseUrl);
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={strings.deleteSaved}>
                      <IconButton
                        className={classes.button}
                        size={"small"}
                        onClick={() => {
                          deleteQuery(query);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}

RecentQueryView.propTypes = {
  baskets: PropTypes.array,
  sharedBaskets: PropTypes.array
};

export default RecentQueryView;
