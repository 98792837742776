import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import SavedQueryView from "../components/queries/SavedQueryView";
import RecentQueryView from "../components/queries/RecentQueryView";
import { LoginContext } from "../context/LoginContext";
import { MobileDrawerContext } from "../context/MobileDrawerContext";
import { useQuery } from "react-query";
import basketRequest from "../requests/basketRequest";
import { requestError } from "../utility/requestError";
import equal from "deep-equal";
import { SessionContext } from "../context/SessionContext";
import sharedBasketRequest from "../requests/sharedBasketRequest";
import useStyles from "../useStyles";

/**
 * Renders the saved search queries and the queries search queries of the currently logged in user.
 *
 * @returns {JSX.Element}
 * @constructor
 */
function SearchQueriesSite() {
  const classes = useStyles();

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const notLoggedInDispatcher = loginContext.notLoggedInDispatcher;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const showMobileDrawerButton = useContext(MobileDrawerContext)
    .showMobileDrawerButtonDispatch;

  // Hide the mobile drawer button.
  useEffect(() => {
    showMobileDrawerButton({ type: "hide" });
  }, [showMobileDrawerButton]);

  const [baskets, setBaskets] = useState([]);
  const [sharedBaskets, setSharedBaskets] = useState([]);

  // Fetch baskets for the current user.
  const basketInfo = useQuery("baskets", basketRequest, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    onError: requestError(sessionExpiredDispatcher, loginStateDispatcher),
    refetchOnMount: false
  });

  // Set baskets on load and update them on a change.
  useEffect(() => {
    if (
      basketInfo.data &&
      basketInfo.data.data &&
      !equal(baskets, basketInfo.data.data)
    ) {
      setBaskets(basketInfo.data.data);
    }
  }, [basketInfo, baskets]);

  // Fetch baskets shared with the current user.
  const sharedBasketInfo = useQuery("sharedBaskets", sharedBasketRequest, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    onError: requestError(sessionExpiredDispatcher, loginStateDispatcher),
    refetchOnMount: false
  });

  // Set shared baskets on load and update them on a change.
  useEffect(() => {
    if (
      sharedBasketInfo.data &&
      sharedBasketInfo.data.data &&
      !equal(sharedBaskets, sharedBasketInfo.data.data)
    ) {
      setSharedBaskets(sharedBasketInfo.data.data);
    }
  }, [sharedBasketInfo, sharedBaskets]);

  if (loginState) {
    return (
      <Grid container justify={"center"} alignItems={"flex-start"}>
        <Grid item xs={12} className={classes.paper}>
          <SavedQueryView baskets={baskets} sharedBaskets={sharedBaskets} />
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <RecentQueryView baskets={baskets} sharedBaskets={sharedBaskets} />
        </Grid>
      </Grid>
    );
  } else {
    notLoggedInDispatcher({ type: "set" });
    return (
      // Redirect to Homepage if not logged in
      <Redirect to={"/"} />
    );
  }
}

export default React.memo(SearchQueriesSite);
