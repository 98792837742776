/**
 * Handle errors from requests.
 *
 * @param sessionExpiredDispatcher Callback function to be called on a 401 error.
 * @param loginStateDispatcher Callback function to be called on a 401 error.
 * @returns {function(*=): void}
 */
export function requestError(sessionExpiredDispatcher, loginStateDispatcher) {
  return function(error) {
    if (error.response) {
      if (error.response.status === 401) {
        sessionExpiredDispatcher({ type: "expired" });
        localStorage.setItem("Bearer", "");
        loginStateDispatcher({ type: "logout" });
      } else {
        console.log(error.response);
      }
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error);
    }
  };
}
