import React, { useContext, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  IconButton,
  Divider,
  TextField,
  Tooltip,
  Select,
  MenuItem
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import SearchIcon from "@material-ui/icons/Search";
import SaveIcon from "@material-ui/icons/Save";
import { strings } from "../../localStrings";
import { useHistory } from "react-router-dom";
import QueryNameInput from "./QueryNameInput";
import { LanguageContext } from "../../context/LanguageContext";
import { Autocomplete } from "@material-ui/lab";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the search bar.
 *
 * @param getInputProps
 * @param mode The current search mode.
 * @param setMode Callback function to set the search mode.
 * @param setFilterConfig Callback function to set the search filters.
 * @param resetFilters Callback function to reset the search filters.
 * @param saveQuery Callback function to save the current search query.
 * @param showSaveButton Should the sace query button be shown?
 * @param autocompleteSuggestions Object with autocomplete suggestions
 * @param onValueChange Callback function to be called when the automplete value changes.
 * @returns {JSX.Element}}
 * @constructor
 */

function InputView({
  getInputProps,
  mode,
  setMode,
  setFilterConfig,
  resetFilters,
  saveQuery,
  showSaveButton,
  autocompleteSuggestions,
  onValueChange
}) {
  const classes = useStyles();
  const language = useContext(LanguageContext); // Rerender on language change

  const history = useHistory();

  const [queryDialogOpen, setQueryDialogOpen] = useState(false);
  const closeDialog = () => {
    setQueryDialogOpen(false);
  };

  const handleChange = event => {
    setFilterConfig({});
    resetFilters();
    setMode(event.target.value);
    history.push("/s/" + event.target.value);
  };

  const tooltip = (
    <div className={classes.tooltip}>{strings.searchOptions}</div>
  );

  const inputProps = getInputProps();

  function getHighlightedText(option) {
    return <span dangerouslySetInnerHTML={{ __html: option.highlight }} />;
  }

  // Label for Autocomplete Options
  function getLabel() {
    return option => {
      if (option === "") return "";
      if (typeof option === "string") {
        return option;
      } else {
        return option.suggestion;
      }
    };
  }

  return (
    <>
      <Paper className={classes.searchInput}>
        <Select value={mode} onChange={handleChange} className={classes.select}>
          <MenuItem component="li" value="elabour" button={false}>
            {strings.studies}
          </MenuItem>
          <MenuItem component="li" value="elabour-contexts" button={false}>
            {" "}
            {strings.context}
          </MenuItem>
        </Select>

        <Tooltip title={tooltip} className={classes.tooltip}>
          <IconButton
            className={classes.iconButton}
            disableRipple
            disableTouchRipple
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>

        <Autocomplete
          freeSolo
          fullWidth
          value={inputProps.value}
          onChange={(event, value) => {
            value !== null && onValueChange(value.suggestion);
          }}
          renderInput={params => (
            <TextField
              {...params}
              onFocus={inputProps.onFocus}
              id={inputProps.id}
              placeholder={inputProps.placeholder}
              onBlur={inputProps.onBlur}
              onChange={inputProps.onChange}
              onKeyDown={inputProps.onKeyDown}
            />
          )}
          options={
            autocompleteSuggestions.documents !== undefined
              ? autocompleteSuggestions.documents.map(option => option)
              : []
          }
          getOptionLabel={getLabel()}
          renderOption={option => {
            return getHighlightedText(option);
          }}
        />

        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          color={"primary"}
        >
          <SearchIcon />
        </IconButton>
        {showSaveButton && (
          <>
            <Divider className={classes.divider} orientation="vertical" />
            <Tooltip title={strings.saveQueryDialog.saveQuery}>
              <IconButton
                className={classes.iconButton}
                color={"primary"}
                onClick={() => {
                  setQueryDialogOpen(true);
                }}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Paper>
      <QueryNameInput
        open={queryDialogOpen}
        handleClose={closeDialog}
        saveQuery={saveQuery}
      />
    </>
  );
}

InputView.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  setFilterConfig: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  saveQuery: PropTypes.func.isRequired,
  showSaveButton: PropTypes.bool.isRequired,
  autocompleteSuggestions: PropTypes.object,
  onValueChange: PropTypes.func.isRequired
};

export default InputView;
