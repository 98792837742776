import { createContext } from "react";

/**
 * Global state for similar baskets.
 *
 * @type {React.Context<{setSimilarBaskets: setSimilarBaskets, removeSimilarBasket: removeSimilarBasket, resetSimilarBaskets: resetSimilarBaskets, similarBaskets: [], addSimilarBasket: addSimilarBasket}>}
 */
export const SimilarBasketsContext = createContext({
  similarBaskets: [],
  setSimilarBaskets: () => {},
  addSimilarBasket: () => {},
  removeSimilarBasket: () => {},
  resetSimilarBaskets: () => {}
});
