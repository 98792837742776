import { useEffect } from "react";

/**
 * Hook for setting the significant terms for a basket.
 *
 * @param content The content of a basket.
 * @param setSignificantTerms Callback function to set the significant terms.
 */
function useSignificantTerms(content, setSignificantTerms) {
  // Get significant terms from content.
  useEffect(() => {
    if (content !== undefined && content.aggregations !== undefined) {
      const terms = content.aggregations.significantTerms.buckets.map(item => {
        return item.key;
      });
      const termsWithoutEmptyElements = terms.filter(e => {
        return e != null;
      });
      setSignificantTerms(termsWithoutEmptyElements);
    }
  }, [content, setSignificantTerms]);
}

export default useSignificantTerms;
