import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

/**
 * Request for autocomplete suggestions.
 *
 * @param search_term The search term.
 * @param mode The search mode.
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export default async function SuggestionRequest(search_term, mode) {
  let payload = {
    term: search_term
  };

  return await axios.post(
    APIBASE + "api/search/" + mode + "/suggestions",
    JSON.stringify(payload),
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("Bearer")
      },
      mode: "CORS"
    }
  );
}
