import React, { useState } from "react";
import { Chip, Typography } from "@material-ui/core";
import { strings } from "../../localStrings";
import PropTypes from "prop-types";
import useSignificantTerms from "../../hooks/useSignificantTerms";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the significant terms for a single basket.
 *
 * @param content The content of the basket.
 * @param basketId The id of the basket.
 * @returns {JSX.Element}
 * @constructor
 */
export function SignificantTerms({ content, basketId }) {
  const classes = useStyles();

  const [significantTerms, setSignificantTerms] = useState([]);

  useSignificantTerms(content, setSignificantTerms);

  return (
    <>
      <Typography variant={"h6"}>{strings.keywords}</Typography>
      <Typography>
        {significantTerms.length === 0 ? strings.basketKeywordPlaceholder : ""}
      </Typography>
      {significantTerms.map((value, index) => {
        return (
          <Chip
            variant="outlined"
            size="small"
            label={value}
            key={basketId + "term" + index}
            className={classes.chipArrayChip}
          />
        );
      })}
    </>
  );
}

SignificantTerms.propTypes = {
  content: PropTypes.object.isRequired,
  basketId: PropTypes.string.isRequired
};
