import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";
import Wordcloud from "../Wordcloud";
import { SaveQuery } from "../../requests/queryHistoryRequests";
import overViewSearchRequest from "../../requests/overviewSearchRequest";
import basketAddDocumentRequest from "../../requests/basketAddDocumentRequest";
import SearchDrawer from "./SearchDrawer";
import {
  Button,
  CircularProgress,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  Tooltip
} from "@material-ui/core";
import BasketIcon from "@material-ui/icons/ShoppingBasket";
import { strings } from "../../localStrings";
import {
  Paging,
  PagingInfo,
  Results,
  SearchBox
} from "@elastic/react-search-ui";
import BetterSearchBox from "./SearchBox";
import PagingView from "./PagingView";
import PagingInfoView from "./PagingInfoView";
import ResultView from "./ResultView";
import ChipArray from "../ChipArray";
import { SimilarContext } from "../../context/SimilarContext";
import { SimilarBasketsContext } from "../../context/SimilarBasketsContext";
import SnackbarContentWrapper from "../SnackbarContentWrapper";
import basketCreateRequest from "../../requests/basketCreateRequest";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
import { requestError } from "../../utility/requestError";
import equal from "deep-equal";
import { LanguageContext } from "../../context/LanguageContext";
import { queryCache, useMutation } from "react-query";
import { CreateBasketDialog } from "../baskets/CreateBasketDialog";
import { SelectBasketDialog } from "../baskets/SelectBasketDialog";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the search interface (search box, search drawer and results) and handles the application of the
 * search terms and the filters to be used in the search query.
 *
 * @param searchTerm The search term.
 * @param mode Document or context search.
 * @param loading If true, waiting for response of a search query.
 * @param initial If true, no search was executed.
 * @param filterConfig The current state of the filter options.
 * @param filters The filters reported by the elasticsearch backend.
 * @param significantTerms The signficant terms returned from the elasictsearch backend used for the wordcloud.
 * @param aggregations The aggregations returned from the elasticsearch backend.
 * @param resultState Object containign the results and their display settings. Must contain: results, resultsPerPage, current.
 * @param callbacks Callback functions. Must contain: setFilter, setMode, setInitial, setFilterConfig, removeFilter, setResultsPerPage, reset.
 * @returns {*}
 * @constructor
 */
function SearchComponent({
  searchTerm,
  mode,
  loading,
  initial,
  filterConfig,
  filters,
  significantTerms,
  aggregations,
  resultState,
  callbacks
}) {
  const classes = useStyles();

  const { results, resultsPerPage, current } = resultState;
  const {
    setSearchTerm,
    setFilter,
    setMode,
    setInitial,
    setFilterConfig,
    removeFilter,
    setResultsPerPage,
    reset
  } = callbacks;

  const language = useContext(LanguageContext); // Rerender on language change

  const [baskets, setBaskets] = useState([]);

  const similarContext = useContext(SimilarContext);
  const similarDocuments = similarContext.similarDocuments;
  const removeSimilarDocument = similarContext.removeSimilarDocument;
  const addSimilarDocument = similarContext.addSimilarDocument;
  const resetSimilarDocuments = similarContext.resetSimilarDocuments;

  const similarBasketsContext = useContext(SimilarBasketsContext);
  const similarBaskets = similarBasketsContext.similarBaskets;
  const removeSimilarBasket = similarBasketsContext.removeSimilarBasket;
  const resetSimilarBaskets = similarBasketsContext.resetSimilarBaskets;

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  // Select docments from results.
  const selectionReducer = (state, action) => {
    switch (action.type) {
      case "add":
        return [...state, action.value];
      case "remove":
        return state.filter(value => {
          return value !== action.value;
        });
      case "reset":
        return [];
      default:
        throw new Error("Action " + action.type + " not available.");
    }
  };

  const [selection, selectionDispatch] = useReducer(selectionReducer, []);

  // Execute the search.
  const trigger_search = useCallback(
    clear => {
      if (Object.keys(filterConfig).length > 0 && !clear) {
        setFilter("filter", filterConfig, "all");
      } else {
        //Remove filter, when no filter option was set
        removeFilter("filter");
      }
    },
    [filterConfig, removeFilter, setFilter]
  );

  // Add a search tearm.
  const append_searchTerm = useCallback(
    term => {
      if (searchTerm.length > 0) {
        setSearchTerm(searchTerm + " " + term, {
          refresh: false
        });
      } else {
        setSearchTerm(term, { refresh: false });
      }

      trigger_search();
    },
    [searchTerm, setSearchTerm, trigger_search]
  );

  const apply_filters = useCallback(
    filter => {
      if (!equal(filterConfig, filter)) {
        setFilterConfig(filter);
      }

      if (Object.keys(filter).length > 0) {
        setFilter("filter", filter, "all");
      } else {
        //Remove filter, when no filter option was set
        removeFilter("filter");
      }
    },
    [filterConfig, setFilterConfig, setFilter, removeFilter]
  );

  // Saves the current search query.
  const save_query = useCallback(
    name => {
      SaveQuery(
        {
          recent: false,
          query: {
            name: name,
            term: searchTerm,
            filters: filterConfig,
            page: current,
            pageSize: resultsPerPage,
            mode: mode,
            like:
              similarDocuments && similarDocuments.length > 0
                ? similarDocuments
                : "",
            likeBaskets:
              similarBaskets && similarBaskets.length > 0 ? similarBaskets : ""
          }
        },
        sessionExpiredDispatcher,
        loginStateDispatcher
      );
    },
    [
      current,
      filterConfig,
      loginStateDispatcher,
      mode,
      resultsPerPage,
      searchTerm,
      sessionExpiredDispatcher,
      similarBaskets,
      similarDocuments
    ]
  );

  // Updates the filter config if a filter changes.
  useEffect(() => {
    if (
      Object.keys(filterConfig).length === 0 &&
      filters.length > 0 &&
      filters[0].values
    ) {
      //Parse filters from filters object (URL)

      let urlFilter = filters[0].values[0];

      setFilterConfig(urlFilter);
    }
  }, [filterConfig, filters, setFilterConfig]);

  const [basketDialogOpen, setBasketDialogOpen] = useState(false);
  const [selectedDialogOpen, setSelectedDialogOpen] = useState(false);
  const [newBasketDialogOpen, setNewBasketDialogOpen] = useState(false);
  const [newSelectionDialogOpen, setNewSelectionDialogOpen] = useState(false);
  const [newBasketName, setNewBasketName] = useState("");
  const [emptyNameOpen, setEmptyNameOpen] = useState(false);

  const closeDialog = () => {
    setBasketDialogOpen(false);
    setSelectedDialogOpen(false);
  };

  const handleCloseNewBasketDialog = () => {
    setNewBasketDialogOpen(false);
    setNewSelectionDialogOpen(false);
  };

  const handleNewBasketDialogOpen = () => {
    setNewBasketDialogOpen(true);
  };

  const handleNewSelectionDialogOpen = () => {
    setNewSelectionDialogOpen(true);
  };

  const handleSnackbarClose = () => {
    setEmptyNameOpen(false);
  };

  const handleBasketDialogOpen = () => {
    setBasketDialogOpen(true);
  };

  const handleSelectedDialogOpen = () => {
    setSelectedDialogOpen(true);
  };

  const handleBasketListItemClick = value => {
    closeDialog();
    if (loginState) {
      if (value !== 0) {
        const overviewRequest = overViewSearchRequest(
          searchTerm,
          mode,
          filters,
          sessionExpiredDispatcher,
          loginStateDispatcher
        );
        overviewRequest().then(response => {
          if (!response.hits.hits.length) {
            // no documents to add
            return;
          }
          let docIds = [];
          for (let i = 0; i < response.hits.hits.length; i++) {
            docIds.push(response.hits.hits[i]._id);
          }
          let resources = {};
          resources[response.hits.hits[0]._index] = docIds;

          basketAddDocumentRequest(value, resources).catch(
            requestError(sessionExpiredDispatcher, loginStateDispatcher)
          );
        });
      } else {
        handleNewBasketDialogOpen();
      }
    }
  };

  const handleSelectedListItemClick = value => {
    closeDialog();
    if (loginState) {
      if (value !== 0) {
        if (selection.length === 0) {
          // no documents to add
          return;
        }
        let docIds = selection;
        let resources = {};
        resources[mode] = docIds;

        basketAddDocumentRequest(value, resources).catch(
          requestError(sessionExpiredDispatcher, loginStateDispatcher)
        );
      } else {
        handleNewSelectionDialogOpen();
      }
    }
  };

  // Create a new basket and invalidate the cache for the baskets query.
  const [createBasketMutation] = useMutation(
    ({ newBasketName, resources }) =>
      basketCreateRequest(newBasketName, resources),
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  const createBasket = () => {
    handleCloseNewBasketDialog();
    if (newBasketName === "") {
      setEmptyNameOpen(true);
    } else {
      const overviewRequest = overViewSearchRequest(
        searchTerm,
        mode,
        filters,
        sessionExpiredDispatcher,
        loginStateDispatcher
      );
      overviewRequest().then(response => {
        if (!response.hits.hits.length) {
          // no documents to add
          return;
        }
        let docIds = [];
        for (let i = 0; i < response.hits.hits.length; i++) {
          docIds.push(response.hits.hits[i]._id);
        }
        let resources = {};
        resources[response.hits.hits[0]._index] = docIds;

        createBasketMutation({ newBasketName, resources }).catch(
          requestError(sessionExpiredDispatcher, loginStateDispatcher)
        );
      });
    }
  };

  const createBasketFromSelection = () => {
    handleCloseNewBasketDialog();
    if (newBasketName === "") {
      setEmptyNameOpen(true);
    } else {
      if (selection.lenght === 0) {
        // no documents to add
        return;
      }
      let docIds = selection;
      let resources = {};
      resources[mode] = docIds;

      createBasketMutation({ newBasketName, resources }).catch(
        requestError(sessionExpiredDispatcher, loginStateDispatcher)
      );
    }
  };

  const resetFilters = useCallback(() => {
    setInitial(true);
    resetSimilarBaskets();
    resetSimilarDocuments();
    reset();
  }, [reset, resetSimilarBaskets, resetSimilarDocuments, setInitial]);

  const searchBoxInputProps = useMemo(() => {
    return { placeholder: strings.searchPlaceholder };
  }, []);

  // Memoize the paging view.
  const pagingView = useMemo(() => {
    return ({
      className,
      current,
      resultsPerPage,
      onChange,
      totalPages,
      ...rest
    }) => {
      return (
        <PagingView
          totalPages={totalPages}
          resultsPerPage={resultsPerPage}
          current={current}
          onChange={onChange}
        />
      );
    };
  }, []);

  // Memoize the paging info view.
  const pagingInfoView = useMemo(() => {
    return ({ className, end, searchTerm, start, totalResults, ...rest }) => {
      return (
        <PagingInfoView
          totalResults={totalResults}
          start={start}
          searchTerm={searchTerm}
          end={end}
        />
      );
    };
  }, []);

  // Memoize the results view.
  const resultView = useMemo(() => {
    return ({
      className,
      result,
      onClickLink,
      titleField,
      urlField,
      ...rest
    }) => {
      return (
        <ResultView
          result={result}
          baskets={baskets}
          addSimilarDocument={addSimilarDocument}
          mode={mode}
          isQueryEmpty={searchTerm === ""}
          selection={selection}
          selectionDispatch={selectionDispatch}
        />
      );
    };
  }, [addSimilarDocument, baskets, mode, searchTerm, selection]);

  // Memoize the search box view.
  const searchBoxView = useMemo(() => {
    return props => (
      <BetterSearchBox
        properties={props}
        triggerSearch={trigger_search}
        mode={mode}
        setMode={setMode}
        setFilterConfig={setFilterConfig}
        resetFilters={resetFilters}
        saveQuery={save_query}
        showSaveButton={!loading && results.length > 0}
      />
    );
  }, [
    loading,
    mode,
    resetFilters,
    results.length,
    save_query,
    setFilterConfig,
    setMode,
    trigger_search
  ]);

  return (
    <div className={classes.searchRoot}>
      <SearchDrawer
        mode={mode}
        filterCallback={apply_filters}
        filterConfig={filterConfig}
        aggregations={aggregations}
        baskets={baskets}
        setBaskets={setBaskets}
        resetFilters={resetFilters}
        initial={initial}
      />
      <main className={classes.content}>
        <Grid container spacing={2}>
          <Grid
            container
            item
            spacing={1}
            md={8}
            xs={12}
            direction="column"
            alignItems="stretch"
          >
            {similarDocuments.length > 0 && (
              <Grid item>
                <ChipArray
                  chipData={similarDocuments}
                  handleDelete={removeSimilarDocument}
                  type={"like"}
                />
              </Grid>
            )}
            {similarBaskets.length > 0 && (
              <Grid item>
                <ChipArray
                  chipData={similarBaskets}
                  handleDelete={removeSimilarBasket}
                  type={"basket"}
                />
              </Grid>
            )}

            <Grid item>
              <SearchBox
                autocompleteSuggestions={true}
                inputProps={searchBoxInputProps}
                view={searchBoxView}
              />
            </Grid>

            {loading && (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                className={classes.loading}
              >
                <Grid item>
                  <CircularProgress
                    className={classes.progress}
                    color="secondary"
                    disableShrink
                  />
                </Grid>
              </Grid>
            )}

            {!loading && (
              <Grid container item spacing={1}>
                {results.length === 0 && !initial && (
                  <Grid item xs={12}>
                    <p>{strings.noResult}</p>
                  </Grid>
                )}

                {!initial && !loading && loginState && results.length > 0 && (
                  <Grid item xs={12}>
                    <Button
                      className={classes.button}
                      size={"small"}
                      variant={"contained"}
                      color={"primary"}
                      onClick={handleBasketDialogOpen}
                    >
                      {strings.addResultsToBasket}
                    </Button>
                  </Grid>
                )}

                {results.length > 0 && (
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    justify={"space-between"}
                  >
                    <Grid item xs={12} md={3}>
                      <PagingInfo view={pagingInfoView} />
                    </Grid>
                    <Grid container item xs={12} md={6} justify={"center"}>
                      <Paging view={pagingView} />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={3}
                      justify={"flex-end"}
                      alignContent={"flex-start"}
                    >
                      <FormControl>
                        <Select
                          value={resultsPerPage}
                          onChange={event => {
                            setResultsPerPage(event.target.value);
                          }}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                        <FormHelperText className={classes.helperText}>
                          {strings.resultsPerPage}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Results
                    titleField="title"
                    urlField="nps_link"
                    resultView={resultView}
                  />
                </Grid>

                {results.length > 0 && (
                  <Grid container item directions="row" justify={"center"}>
                    <Paging view={pagingView} />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item />
          </Grid>
          <Grid item md={4} sm={12}>
            {!loading && results.length > 0 && (
              <Wordcloud
                significantTerms={significantTerms}
                append_searchTerm={append_searchTerm}
              />
            )}
          </Grid>
        </Grid>
        {selection.length !== 0 && (
          <Tooltip title={strings.addSelectionToBasket}>
            <Fab
              color={"primary"}
              className={classes.fab}
              onClick={handleSelectedDialogOpen}
            >
              <BasketIcon />
            </Fab>
          </Tooltip>
        )}
      </main>
      <SelectBasketDialog
        open={basketDialogOpen}
        onClose={closeDialog}
        baskets={baskets}
        onItemClick={handleBasketListItemClick}
      />
      <SelectBasketDialog
        open={selectedDialogOpen}
        onClose={closeDialog}
        baskets={baskets}
        onItemClick={handleSelectedListItemClick}
      />

      <CreateBasketDialog
        open={newBasketDialogOpen}
        onClose={handleCloseNewBasketDialog}
        onChange={event => setNewBasketName(event.target.value)}
        onCreate={createBasket}
      />
      <CreateBasketDialog
        open={newSelectionDialogOpen}
        onClose={handleCloseNewBasketDialog}
        onChange={event => setNewBasketName(event.target.value)}
        onCreate={createBasketFromSelection}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={emptyNameOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={"error"}
          message={strings.userSite.basketNameEmptyText}
        />
      </Snackbar>
    </div>
  );
}

SearchComponent.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  initial: PropTypes.bool.isRequired,
  filterConfig: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  significantTerms: PropTypes.object.isRequired,
  aggregations: PropTypes.object.isRequired,
  resultState: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired
};

function areEqual(prevProps, nextProps) {
  return equal(prevProps, nextProps);
}

export default React.memo(SearchComponent, areEqual);
