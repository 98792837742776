const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

/**
 * Initializes the filedownload in the webbrowser.
 *
 * @param path The path of the file.
 * @param filename The name of the file.
 */
export function fileDownload(path, filename = "") {
  const url = new URL(APIBASE + path);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.click();
}
