/**
 * Initialize the pdfjs backend.
 */
export default class PDFJs {
  init = (source, element, search) => {
    const iframe = document.createElement("iframe");

    const packageJson = require("../../package.json");
    const url = new URL(packageJson.homepage);

    if (search === undefined) {
      iframe.src =
        url.pathname + `pdfjs-2.4.456-dist/web/viewer.html?file=${source}`;
    } else {
      iframe.src =
        url.pathname +
        `pdfjs-2.4.456-dist/web/viewer.html?file=${source}#search="${search}"`;
    }

    iframe.width = "100%";
    iframe.height = "100%";

    const firstChild = element.firstChild;

    if (firstChild === null) {
      element.appendChild(iframe);
    } else {
      element.replaceChild(iframe, firstChild);
    }
  };
}
