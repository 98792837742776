import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/baskets/";

/**
 * Request to remove documents from a basket.
 * @param basketId The id of the basket.
 * @param resources The documents to be removed.
 * @returns {Promise<AxiosResponse<any>>}
 */
export default async function basketRemoveDocumentRequest(basketId, resources) {
  return await axios.post(
    APIURL + basketId + "/remove",
    {
      resources: resources
    },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Bearer")
      }
    }
  );
}
