import ticketRequest from "../requests/ticketRequest";
import { fileDownload } from "./fileDownload";
import { requestError } from "./requestError";

/**
 * Downloads a single document.
 *
 * @param sessionExpiredDispatcher Callback function to be called on a 401 error.
 * @param loginStateDispatcher Callback function to be called on a 401 error.
 * @returns {function(*, *): void}
 */
export default function downloadDocument(
  sessionExpiredDispatcher,
  loginStateDispatcher
) {
  return (documentIndex, documentId) => {
    ticketRequest()
      .then(response => {
        const ticket = response.data.ticket;
        const path = `api/file/${documentIndex}/${documentId}?ticket=${ticket}`;
        fileDownload(path);
      })
      .catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));
  };
}
