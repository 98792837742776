import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

/**
 * Request for a single document.
 *
 * @param mode The search mode.
 * @param documentId The id of the document.
 * @param source Token to cancel the request.
 * @returns {Promise<*>}
 */
export default async function documentRequest(mode, documentId, source) {
  return await axios({
    method: "get",
    url: APIBASE + "api/search/" + mode + "/document/" + documentId,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "cors",
    cancelToken: source.token
  });
}
