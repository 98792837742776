import {
  Chip,
  Checkbox,
  ListItem,
  ListItemIcon,
  Typography
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import useStyles from "../../../useStyles";

/**
 * Renders a single entry for the facet filter.
 *
 * @param entry The facet entry.
 * @param onToggle Callback function to be called when the filter is (de)selected.
 * @param checked Array with the selected filter values.
 * @param docCount Number of documents for this facet entry.
 * @returns {JSX.Element}
 * @constructor
 */
export function FilterEntry({ entry, onToggle, checked, docCount }) {
  const classes = useStyles();

  // Filter empty entries.
  if (entry.key === "<OTHER>" || entry.key === "" || entry.key === null) {
    return null;
  }

  return (
    <ListItem dense button onClick={onToggle(entry.key)}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={
            Array.isArray(checked) ? checked.indexOf(entry.key) !== -1 : false
          }
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <Typography className={classes.FilterItemText}>{entry.key}</Typography>
      <Chip
        className={classes.FilterItemCount}
        label={docCount}
        color={"secondary"}
        size="small"
      />
    </ListItem>
  );
}

FilterEntry.propTypes = {
  entry: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  docCount: PropTypes.number.isRequired
};
