import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "/api/baskets";

/**
 * Request to revoke the sharing of a basket.
 *
 * @param id The id of the basket.
 * @param user The user the sharing should be revoked for.
 * @returns {Promise<AxiosResponse<any>>}
 */
export default async function basketUnshareRequest(id, user) {
  const url = `${APIURL}/${id}/unshare`;
  return await axios.post(
    url,
    { user: user },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Bearer")
      }
    }
  );
}
