import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Snackbar,
  TextField
} from "@material-ui/core";
import { AccountCircle, Lock } from "@material-ui/icons";
import { strings } from "../localStrings";
import SnackbarContentWrapper from "../components/SnackbarContentWrapper";
import { Redirect } from "react-router-dom";
import loginRequest from "../requests/loginRequest";
import { LoginContext } from "../context/LoginContext";
import { MobileDrawerContext } from "../context/MobileDrawerContext";
import { SessionContext } from "../context/SessionContext";
import { LanguageContext } from "../context/LanguageContext";
// Import useStyles last!
import useStyles from "../useStyles";

/**
 * Renders the login site.
 *
 * @returns {JSX.Element}
 */
function LoginSite() {
  const language = useContext(LanguageContext); // Rerender on language change

  const showMobileDrawerButton = useContext(MobileDrawerContext)
    .showMobileDrawerButtonDispatch;

  // Hide the mobile drawer button.
  useEffect(() => {
    showMobileDrawerButton({ type: "hide" });
  }, [showMobileDrawerButton]);

  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(strings.logInError);

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const loginSuccessDispatcher = sessionContext.loginSuccessDispatcher;

  // Sends login request and handles response.
  const handleLogin = () => {
    setLoading(true);

    loginRequest(username, password)
      .then(function(response) {
        localStorage.setItem("Bearer", response.data["token"]);
        loginStateDispatcher({ type: "login" });
        loginSuccessDispatcher({ type: "success" });
      })
      .catch(function(error) {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorText(strings.logInError);
          } else {
            setErrorText(strings.error);
          }
        } else {
          setErrorText(strings.error);
        }
        loginStateDispatcher({ type: "logout" });
        localStorage.setItem("Bearer", "");
        setErrorOpen(true);
        setLoading(false);
      });

    setPassword("");
  };

  // Closes snackbars.
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  }

  const keyPressed = e => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  if (loginState) {
    // Redirect to Homepage after login.
    return <Redirect to={"/"} />;
  } else {
    if (loading) {
      return (
        <Paper>
          <Grid
            container
            justify={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <Grid item xs={12}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                disableShrink
              />
            </Grid>
          </Grid>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.paper}>
          <Grid
            container
            justify={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <Grid item xs={12}>
              <TextField
                id={"username"}
                label={strings.username}
                margin={"normal"}
                variant={"outlined"}
                onChange={event => setUsername(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id={"password"}
                label={strings.password}
                margin={"normal"}
                variant={"outlined"}
                type={"password"}
                fullWidth
                onChange={event => setPassword(event.target.value)}
                onKeyDown={e => keyPressed(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleLogin}
              >
                {strings.logIn}
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={errorOpen}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <SnackbarContentWrapper
              onClose={handleClose}
              variant={"error"}
              message={errorText}
            />
          </Snackbar>
        </Paper>
      );
    }
  }
}

export default React.memo(LoginSite);
