import { createContext } from "react";

/**
 * Global state for the curent language.
 *
 * @type {React.Context<{languageChanged: boolean, language: string, languageDispatch: languageDispatch}>}
 */
export const LanguageContext = createContext({
  language: "de",
  languageDispatch: () => {},
  languageChanged: false
});
