import React, { useContext } from "react";
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import { strings } from "../../localStrings";
import { Link as RouterLink } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import downloadDocument from "../../utility/downloadDocument";
import { SessionContext } from "../../context/SessionContext";
import { LoginContext } from "../../context/LoginContext";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the documents contained in a single basket as a table.
 *
 * @param content The content of the basket.
 * @param shared True, iff it is a shared basekt.
 * @param handleRemoveDocument Callback function to remove a document from the basket.
 * @returns {JSX.Element}
 * @constructor
 */
function BasketDocumentTable({ content, shared, handleRemoveDocument }) {
  const classes = useStyles();

  const loginStateDispatcher = useContext(LoginContext).loginStateDispatcher;
  const sessionExpiredDispatcher = useContext(SessionContext)
    .sessionExpiredDispatcher;

  const handleDownloadDocument = downloadDocument(
    sessionExpiredDispatcher,
    loginStateDispatcher
  );

  return (
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{strings.userSite.document}</TableCell>
            <TableCell>{strings.documentDetails.study}</TableCell>
            <TableCell>{strings.documentDetails.docClass}</TableCell>
            <TableCell>{strings.documentDetails.explorationType}</TableCell>
            <TableCell>{strings.documentDetails.pupYear}</TableCell>
            <TableCell align={"right"}>{strings.actions}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {content.hits.hits.map((value, index) => {
            return (
              <TableRow hover key={value._id}>
                <TableCell>{index + 1}.</TableCell>
                <TableCell>
                  {value._index === "elabour" && (
                    <Link
                      component={RouterLink}
                      to={"/document/" + value._id}
                      color={"inherit"}
                      variant={"button"}
                    >
                      {value._source.title}
                    </Link>
                  )}
                  {value._index === "elabour-contexts" && (
                    <Link
                      component={RouterLink}
                      to={"/context/" + value._id}
                      color={"inherit"}
                      variant={"button"}
                    >
                      {value._source.title}
                    </Link>
                  )}
                </TableCell>
                <TableCell>{value._source["study-title"]}</TableCell>
                <TableCell>{value._source["document-class"]}</TableCell>
                <TableCell>{value._source["exploration-type"]}</TableCell>
                <TableCell>{value._source["publication-date"]}</TableCell>
                <TableCell align={"right"} className={classes.basketButtons}>
                  <Tooltip title={strings.downloadDocument}>
                    <IconButton
                      className={classes.rightMargin}
                      size={"small"}
                      onClick={() =>
                        handleDownloadDocument(value._index, value._id)
                      }
                    >
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  {!shared && (
                    <Tooltip title={strings.deleteDocument}>
                      <IconButton
                        size={"small"}
                        onClick={() =>
                          handleRemoveDocument(value._index, value._id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasketDocumentTable.propTypes = {
  content: PropTypes.object.isRequired,
  shared: PropTypes.bool,
  handleRemoveDocument: PropTypes.func
};

export default BasketDocumentTable;
