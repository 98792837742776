import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "/api/baskets";

/**
 * Request to share a basket with another user.
 * @param id The id of the basket.
 * @param user The user to share the basket with.
 * @returns {Promise<AxiosResponse<any>>}
 */
export default async function basketShareRequest(id, user) {
  const url = `${APIURL}/${id}/share`;
  return await axios.post(
    url,
    { user: user },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Bearer")
      }
    }
  );
}
