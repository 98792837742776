/**
 * The order in which the filters appear in the search drawer. If a new filter is added, it has to be added to
 * this array and the display name has the be added to localStrings.js to facets.
 */
export const filterOrder = [
  "exploration-type",
  "document-class",
  "research-area",
  "study-title",
  "case",
  "wave"
];
