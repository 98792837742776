import React, { useContext, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { strings } from "../../localStrings";
import basketShareRequest from "../../requests/basketShareRequest";
import { requestError } from "../../utility/requestError";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
import basketUnshareRequest from "../../requests/basketUnshareRequest";
import { queryCache, useMutation } from "react-query";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Shows baskets shared with other users and provides options to share baskets with other users.
 *
 * @param basketId The id of the basket.
 * @param sharedWith The users the basket is shared with.
 * @returns {JSX.Element}
 * @constructor
 */
function BasketShareView({ basketId, sharedWith }) {
  const classes = useStyles();
  const loginContext = useContext(LoginContext);
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const [shareWith, setShareWith] = useState("");

  // Share the basket with an user and invalidate the cache for the baskets query.
  const [shareMutation] = useMutation(
    user => {
      basketShareRequest(basketId, user);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  // Stop sharing the basket with an user and invalidate the cache for the baskets query.
  const [unshareMutation] = useMutation(
    user => {
      basketUnshareRequest(basketId, user);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  const handleShare = () => {
    if (shareWith.length !== 0) {
      shareMutation(shareWith).catch(
        requestError(sessionExpiredDispatcher, loginStateDispatcher)
      );
    }

    setShareWith("");
  };

  const handleUnshare = user => {
    unshareMutation(user).catch(
      requestError(sessionExpiredDispatcher, loginStateDispatcher)
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>{strings.basketSharedWith}</Typography>
        <List dense>
          {sharedWith &&
            sharedWith.map(value => {
              return (
                <ListItem key={basketId + "_" + value}>
                  <ListItemIcon>
                    <IconButton
                      edge={"end"}
                      onClick={() => handleUnshare(value)}
                      size={"small"}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={value} />
                </ListItem>
              );
            })}
        </List>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id={"shareBasket" + basketId}
          variant={"outlined"}
          label={strings.shareBasketInput}
          color={"primary"}
          size={"small"}
          onChange={event => setShareWith(event.target.value)}
          value={shareWith}
        />
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={handleShare}
          className={classes.shareBasketButton}
        >
          {strings.shareBasketButton}
        </Button>
      </Grid>
    </Grid>
  );
}

BasketShareView.propTypes = {
  basketId: PropTypes.string.isRequired,
  sharedWith: PropTypes.arrayOf(PropTypes.string)
};

export default BasketShareView;
