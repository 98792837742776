import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    platform: "Platform",
    search: "Search",
    homepage: "Homepage",
    ingest: "Ingest",
    baskets: "Baskets",
    sharedBaskets: "Shared Baskets",
    searchQueries: "Search Queries",
    logIn: "Log in",
    logo: "Logo",
    logOut: "Log out",
    languageSelect: "select language",
    language: "English",
    document: "Document View",
    similarDocuments: "Search for similar documents",
    addSimilarDocument: "Add document",
    similarDialogTitle: "Add similar documents",
    similarDialogText:
      "Add the documents to be used in the search, separated by semicolons.",
    addDocuments: "Add documents",
    wordcloud: "Wordcloud",
    previous: "Previous",
    next: "Next",
    page: "Page",
    of: "of",
    noDocument: "Document not available.",
    study: "Study",
    studies: "Studies",
    context: "Context",
    noResult: "No results were found.",
    searchPlaceholder: "Search for documents",
    username: "Username",
    password: "Password",
    logInSuccess: "Successfully logged in.",
    logInError: "Error: Wrong username or password.",
    loginExpired: "Session expired.",
    error: "Error! Please try again!",
    notLoggedIn: "Content only visible if logged in.",
    notFound: "Requested document not found",
    cancel: "Cancel",
    create: "Create",
    rename: "Rename",
    name: "Name",
    savedQueries: "Saved queries",
    recentQueries: "Recently submitted search queries",
    searchDate: "Date of search",
    searchTerm: "Search term",
    chosenFilters: "Chosen Filters",
    actions: "Actions",
    similiarTo: "Similar to",
    addResultsToBasket: "Add all results to basket",
    addSelectionToBasket: "Add selected results to basketn",
    contact: "Contact",
    showing: "Showing results",
    resultsPerPage: "Results per page",
    basketSearch: "Search documents similar to basket",
    addBasketSearch: "Add basket to search for similar documents",
    newBasket: "New basket",
    basketKeywordPlaceholder:
      "No keywords available yet. Please add more documents.",
    delete: "Delete",
    resetFilter: "Reset Filters",
    deleteDocument: "Delete document from basket",
    downloadDocument: "Download document",
    downloadBasket: "Download basket",
    shareBasketInput: "Username",
    shareBasketButton: "Share basket",
    basketSharedWith: "Basket shared with:",
    owner: "Owner",
    keywords: "Keywords",
    executeSaved: "Execute seach",
    deleteSaved: "Delete saved search",
    searchOptions:
      "Search operations:\n" +
      "* | signifies OR operation\n" +
      "* - negates a single token\n" +
      '* " wraps a number of tokens to signify a phrase for searching\n' +
      "* * at the end of a term signifies a prefix query\n" +
      "* ( and ) signify precedence",
    saveQueryDialog: {
      saveQuery: "Save Query",
      queryName: "Name",
      dialogSubmit: "Save"
    },
    searchDrawer: {
      yearFilter: "Collection Timespan",
      to: "to",
      from: "from",
      studyTitleFilter: "Study",
      institutionFilter: "Institution",
      instrumentFilter: "Type of Instrument",
      researchFilter: "Research Area",
      documentType: "Document Type",
      applyFilter: "Apply Filter",
      noFilter: "No filter available.",
      noBasket: "No basket available.",
      basketFilter: "Baskets",
      explorationType: "Survey Type",
      documentClass: "Document Class",
      facets: {
        "study-title": "Study",
        "exploration-type": "Survey Type",
        "document-class": "Document Class",
        "research-area": "Research Area",
        "case": "Case",
        "wave": "Wave"
      }
    },
    documentDetails: {
      topic: "Research Topic",
      study: "Study",
      pupYear: "Year of Puplication",
      docType: "Document Type",
      docClass: "Document Class",
      institution: "Institution",
      instrument: "Instrument Type",
      author: "Author",
      addToBasket: "Add to basket",
      selectBasket: "Select basket",
      back: "Back to search",
      case: "Case",
      wave: "Wave",
      researchTimespan: "Research Timespan",
      collectionTimespan: "Collection Timespan",
      explorationType: "Exploration Type",
      similarSearch: "Search similar documents"
    },
    userSite: {
      user: "Username",
      baskets: "Baskets",
      addBasket: "Create basket",
      basketDialogTitle: "Create new basket",
      basketName: "Basket title",
      basketDialogText: "Please enter a title for the new basket.",
      basketNameEmptyText: "The title may not be empty.",
      basketRename: "Rename basket",
      basketRenameDialog: "Please enter the new name for the basket.",
      from: "since",
      to: "until",
      all_words: "Contains all: ",
      any_words: "Contains any: ",
      not_occuring: "Without: ",
      phrase: "Contains exactly: ",
      title: "Title: ",
      document: "Document"
    }
  },
  de: {
    platform: "Plattform",
    search: "Suchen",
    homepage: "Startseite",
    ingest: "Ingest",
    baskets: "Sammlungen",
    sharedBaskets: "Geteilte Sammlungen",
    searchQueries: "Suchanfragen",
    logIn: "Einloggen",
    logo: "Logo",
    logOut: "Ausloggen",
    languageSelect: "Sprache wählen",
    language: "Deutsch",
    document: "Dokumentenansicht",
    similarDocuments: "Suche ähnliche Dokumente",
    addSimilarDocument: "Dokument hinzufügen",
    similarDialogTitle: "Ähnliche Dokumente hinzufügen",
    similarDialogText:
      "Fügen Sie die Dokumente hinzu, die in der Suche verwendet werden sollen, getrennt duch Semikolons.",
    addDocuments: "Dokumente hinzufügen",
    wordcloud: "Wordcloud",
    previous: "Vorherige",
    next: "Nächste",
    page: "Seite",
    of: "von",
    noDocument: "Dokument nicht verfügbar.",
    study: "Studie",
    studies: "Studien",
    context: "Kontext",
    noResult: "Es wurden keine Ergebnisse gefunden.",
    searchPlaceholder: "Nach Dokumenten suchen",
    username: "Nutzername",
    password: "Passwort",
    logInSuccess: "Erfolgreich eingeloggt.",
    logInError: "Fehler: Falscher Nutzername oder Passwort.",
    loginExpired: "Sitzung abgelaufen.",
    error: "Fehler! Bitte versuchen sie es nochmal!",
    notLoggedIn: "Inhalt nur für angemeldete Nutzer sichtbar.",
    notFound: "Angefragtes Dokument nicht gefunden",
    cancel: "Abbrechen",
    create: "Erstellen",
    rename: "Umbenennen",
    savedQueries: "Gespeicherte Suchanfragen",
    name: "Name",
    recentQueries: "Zuletzt ausgeführte Suchanfragen",
    searchDate: "Suchdatum",
    searchTerm: "Suchbegriff",
    chosenFilters: "Ausgewählte Filter",
    actions: "Aktionen",
    similiarTo: "Ähnlich zu",
    addResultsToBasket: "Alle Ergebnisse zu Sammlung hinzufügen",
    addSelectionToBasket: "Ausgewählte Ergebnisse zu Sammlung hinzufügen",
    contact: "Kontakt",
    showing: "Zeige Ergebnisse",
    resultsPerPage: "Ergebnisse pro Seite",
    basketSearch: "Suche Dokumente ähnlich zur Sammlung",
    addBasketSearch: "Sammlung zur Suche nach ähnlichen Dokumenten hinzufügen",
    newBasket: "Neue Sammlung",
    basketKeywordPlaceholder:
      "Noch keine Schlagwörter verfügbar. Fügen Sie weitere Dokumente hinzu.",
    delete: "Löschen",
    resetFilter: "Filter zurücksetzen",
    deleteDocument: "Dokument aus Sammlung löschen",
    downloadDocument: "Dokument herunterladen",
    downloadBasket: "Sammlung herunterladen",
    shareBasketInput: "Nutzername",
    shareBasketButton: "Sammlung teilen",
    basketSharedWith: "Sammlung geteilt mit:",
    owner: "Besitzer",
    keywords: "Schlagwörter",
    executeSaved: "Suche ausführen",
    deleteSaved: "Gespeicherte Suche löschen",
    searchOptions:
      "Suchoperatoren:\n" +
      "* | ODER Verknüpfung (Beispiel: Arbeit | Job)\n" +
      "* - schließt ein einzelnen Wortes aus (Beispiel: Arbeitszeit -Schichtarbeit)\n" +
      '* " umschließt Wörter für die Suche nach einem Satz (Beispiel: "Tätigkeit im Unternehmen")\n' +
      "* * für ein offenes Wortende (Beispiel: Arbeits*)\n" +
      "* ( ) gruppiert Ausdrücke (Beispiel: (Arbeit | Job) Suche)",
    saveQueryDialog: {
      saveQuery: "Suchanfrage speichern",
      queryName: "Name",
      dialogSubmit: "Speichern"
    },
    searchDrawer: {
      yearFilter: "Erhebungszeitraum",
      to: "bis",
      from: "von",
      studyTitleFilter: "Studie",
      institutionFilter: "Institution",
      instrumentFilter: "Instrumententyp",
      researchFilter: "Forschungsgebiet",
      documentType: "Dokumententyp",
      applyFilter: "Filter anwenden",
      noFilter: "Kein Filter verfügbar.",
      noBasket: "Keine Sammlung verfügbar.",
      basketFilter: "Sammlungen",
      explorationType: "Typ der Erhebungsmaßnahme",
      documentClass: "Dokumentenklasse",
      facets: {
        "study-title": "Studie",
        "exploration-type": "Typ der Erhebungsmaßnahme",
        "document-class": "Dokumentenklasse",
        "research-area": "Forschungsgebiet",
        "case": "Fall",
        "wave": "Welle"
      }
    },
    documentDetails: {
      topic: "Forschungsthema",
      study: "Studie",
      pupYear: "Jahr der Veröffentlichung",
      docType: "Dokumententyp",
      docClass: "Documentenklasse",
      institution: "Institution",
      instrument: "Instrumententyp",
      author: "Autor",
      addToBasket: "Zu Sammlung hinzufügen",
      selectBasket: "Sammlung auswählen",
      back: "Zurück zur Suche",
      case: "Fall",
      wave: "Welle",
      researchTimespan: "Untersuchungszeitraum",
      collectionTimespan: "Studien Erhebungszeitraum",
      explorationType: "Typ der Erhebungsmaßnahme",
      similarSearch: "Ähnliche Dokumente suchen"
    },
    userSite: {
      user: "Nutzername",
      baskets: "Sammlungen",
      addBasket: "Sammlung erstellen",
      basketDialogTitle: "Neue Sammlung erstellen",
      basketName: "Sammlungsname",
      basketDialogText:
        "Bitte geben Sie einen Namen für die neue Sammlung ein.",
      basketNameEmptyText: "Der Name darf nicht leer sein.",
      basketRename: "Sammlung umbenennen",
      basketRenameDialog:
        "Bitte geben Sie den neuen Namen für die Sammlung ein.",
      from: "ab",
      to: "bis",
      all_words: "Enthält alle: ",
      any_words: "Enthält eines: ",
      not_occuring: "Enthält nicht: ",
      phrase: "Enthält genau: ",
      title: "Titel: ",
      document: "Dokument"
    }
  }
});

export {strings};
