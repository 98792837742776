import { createContext } from "react";

/**
 * Global state for the mobile drawer.
 *
 * @type {React.Context<{mobileDrawerDispatch: mobileDrawerDispatch, showMobileDrawerButtonDispatch: showMobileDrawerButtonDispatch, mobileDrawerState: boolean, showMobileDrawerButton: boolean}>}
 */
export const MobileDrawerContext = createContext({
  mobileDrawerState: false,
  showMobileDrawerButton: false,
  mobileDrawerDispatch: () => {},
  showMobileDrawerButtonDispatch: () => {}
});
