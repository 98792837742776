import { createContext } from "react";

/**
 * Global state for similar documents.
 *
 * @type {React.Context<{similarDocuments: [], resetSimilarDocuments: resetSimilarDocuments, addSimilarDocument: addSimilarDocument, setSimilarDocuments: setSimilarDocuments, removeSimilarDocument: removeSimilarDocument}>}
 */
export const SimilarContext = createContext({
  similarDocuments: [],
  addSimilarDocument: () => {},
  removeSimilarDocument: () => {},
  resetSimilarDocuments: () => {},
  setSimilarDocuments: () => {}
});
