import { createContext } from "react";

/**
 * Global state for the location.
 *
 * @type {React.Context<{search: string, pathname: string}>}
 */
export const LocationContext = createContext({
  pathname: "",
  search: ""
});
