import React from "react";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import PdfView from "./PdfView";
import { strings } from "../../localStrings";
import PDFJs from "../../utility/PDFJs";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the document details and the document itself if a pdf is available.
 *
 * @param fileUrl The url of the pdf file.
 * @param loading
 * @param blob The pdf binary.
 * @param search The search tearm used to search the pdf and open it at the first match.
 * @returns {JSX.Element}
 * @constructor
 */
function DocumentView({ fileUrl, loading, blob, search }) {
  const classes = useStyles();

  if (loading) {
    return (
      <Paper>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid item xs={12}>
            <CircularProgress
              className={classes.progress}
              color="secondary"
              disableShrink
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
  if (blob.type === "application/pdf") {
    // Render the Document
    return <PdfView fileUrl={fileUrl} backend={PDFJs} search={search} />;
  } else {
    return (
      <Grid
        container
        spacing={0}
        justify={"center"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Grid item>
          <Paper>
            {!loading && (
              <Typography className={classes.documentNotFound}>
                {strings.noDocument}
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

DocumentView.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  blob: PropTypes.any.isRequired,
  search: PropTypes.string
};

export default React.memo(DocumentView);
