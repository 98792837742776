import React from "react";
import Pagination from "material-ui-flat-pagination";
import PropTypes from "prop-types";

/**
 * Renders the pagination navigation.
 *
 * @param current The current page index.
 * @param resultsPerPage The number of results per page.
 * @param onChange Callback function to be called when the current page changes.
 * @param totalPages The total number of pages.
 * @returns {JSX.Element}
 */
function PagingView({ current, resultsPerPage, onChange, totalPages }) {
  return (
    <Pagination
      limit={resultsPerPage}
      offset={(current - 1) * resultsPerPage}
      total={totalPages * resultsPerPage}
      onClick={(e, offset, page) => onChange(page)}
    />
  );
}

PagingView.propTypes = {
  current: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default PagingView;
