import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/baskets?shared=true";

/**
 * Request for baskets shared with the current user.
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export default async function sharedBasketRequest() {
  return await axios.get(APIURL, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    }
  });
}
