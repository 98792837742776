import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

/**
 * Request for the search facets.
 * @param mode The search mode.
 * @returns {AxiosPromise<any>}
 */
export default async function facetsRequest(mode) {
  return axios({
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    },
    url: APIBASE + "api/search/" + mode + "/facets"
  });
}
