import React from "react";
import {Grid, Hidden, Typography} from "@material-ui/core";
import {strings} from "../localStrings";
import PropTypes from "prop-types";
// import useStyles last!
import useStyles from "../useStyles";
import {Link} from "react-router-dom";

/**
 * Renders the page header.
 *
 * @param basename
 * @returns {JSX.Element}
 */
export const PageHeader = React.memo(function PageHeader({basename}) {
  const classes = useStyles();

  return (
    <div className={[classes.above_drawer]}>
      <Grid
        container
        spacing={0}
        justify={"space-around"}
        alignItems={"center"}
      >
        <Grid item md={3} xs={12}>
          <Link to="/">
            <img
              src={basename + "/logo.svg"}
              alt={strings.logo}
              className={classes.logo}
            />
          </Link>
        </Grid>
        <Grid item md={5}>
          <Hidden mdDown>
            <Typography variant={"h5"}>
              Neue Konturen von Produktion und Arbeit.
            </Typography>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
});

PageHeader.propTypes = {
  basename: PropTypes.string
};
