import DrawerListEntry from "./DrawerListEntry";
import { strings } from "../../localStrings";
import BasketFilter from "./drawerFilters/BasketFilter";
import YearFilter from "./drawerFilters/YearFilter";
import FacetFilter from "./drawerFilters/FacetFilter";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Paper from "@material-ui/core/Paper";
import equal from "deep-equal";
import { LanguageContext } from "../../context/LanguageContext";
import FilterResetButton from "./drawerFilters/FilterResetButton";
import PropTypes from "prop-types";
import GroupedFacetFilter from "./drawerFilters/GroupedFacetFilter";

/**
 * Renders the content of the search drawer as a list of filter options.
 *
 * @param baskets The baskets of the current user.
 * @param filterConfig Object containing the filter state. Must contain: basketFilter, yearFilterFrom, yearFilterTo,
 * maxYear, minYear, YEAR_MIN_VALUE, apiFacets, and an object with all filters that have non-empty buckets.
 * @param facetCounts Object containing the current document counts for the facets. Must contain entries for all
 * filters that have non-empty buckets.
 * @param filterCallbacks Object containing callback function to set the filter. Must contain: setBasketFilter,
 * setFilterChanged, setYearFilterFrom, setYearFilterTo, and an object with callbacks for all filters that have non-empty buckets.
 * @param resetFilters Resets all filters and the search state.
 * @param initial True, if no search has been executed.
 * @param filterOrder Array containing the filter keys in the desired order.
 * @returns {JSX.Element}
 * @constructor
 */
function DrawerContent({
  baskets,
  filterConfig,
  facetCounts,
  filterCallbacks,
  resetFilters,
  initial,
  filterOrder
}) {
  const language = useContext(LanguageContext); // Rerender on language change

  const {
    basketFilter,
    yearFilterFrom,
    yearFilterTo,
    maxYear,
    minYear,
    YEAR_MIN_VALUE,
    apiFacets,
    filterFacets
  } = filterConfig;
  const {
    setBasketFilter,
    setFilterChanged,
    setYearFilterFrom,
    setYearFilterTo,
    facetCallbacks
  } = filterCallbacks;

  const [yearFilterChecked, setYearFilterChecked] = useState([]);

  // Getting facets that have buckets that are not empty
  const facets = [];
  const groupedFacets = [];
  for (let key of filterOrder) {
    if (
      apiFacets[key] !== undefined &&
      apiFacets[key].buckets !== undefined &&
      Array.isArray(apiFacets[key].buckets) &&
      apiFacets[key].buckets.length !== 0
    ) {
      facets.push(key);
      for (let subKey of filterOrder) {
        if (apiFacets[key].buckets[0][subKey] !== undefined) {
          groupedFacets.push({ parent: key, facet: subKey });
        }
      }
    }
  }

  // Correct the behavior of the year filter.
  useEffect(() => {
    if (yearFilterFrom === "" && yearFilterTo === "") {
      setYearFilterChecked([]);
    } else {
      setYearFilterChecked(["open"]);
    }
  }, [maxYear, minYear, yearFilterFrom, yearFilterTo]);

  // Memoize the basket filter,
  const basketFilterBody = useMemo(() => {
    return (
      <BasketFilter
        setFilterChanged={setFilterChanged}
        baskets={baskets}
        checked={basketFilter}
        setChecked={setBasketFilter}
      />
    );
  }, [setFilterChanged, baskets, basketFilter, setBasketFilter]);

  // Memoize the year filter.
  const yearFilterBody = useMemo(() => {
    return (
      <YearFilter
        setFilterChanged={setFilterChanged}
        yearFilterFrom={yearFilterFrom}
        yearFilterTo={yearFilterTo}
        setYearFilterFrom={setYearFilterFrom}
        setYearFilterTo={setYearFilterTo}
        maxYear={maxYear}
        minYear={minYear}
        YEAR_MIN_VALUE={YEAR_MIN_VALUE}
      />
    );
  }, [
    YEAR_MIN_VALUE,
    maxYear,
    minYear,
    setFilterChanged,
    setYearFilterFrom,
    setYearFilterTo,
    yearFilterFrom,
    yearFilterTo
  ]);

  return (
    <Paper>
      <FilterResetButton resetFilters={resetFilters} />
      <DrawerListEntry
        text={strings.searchDrawer.basketFilter}
        body={basketFilterBody}
        checked={basketFilter}
      />
      <DrawerListEntry
        text={strings.searchDrawer.yearFilter}
        body={yearFilterBody}
        checked={yearFilterChecked}
      />

      {facets.map(facet => {
        return (
          <DrawerListEntry
            key={facet}
            text={strings.searchDrawer.facets[facet]}
            checked={filterFacets[facet]}
            body={
              <FacetFilter
                setFilterChanged={setFilterChanged}
                facet={apiFacets[facet]}
                checked={filterFacets[facet]}
                setChecked={update =>
                  facetCallbacks(state => {
                    const newState = { ...state };
                    newState[facet] = update;
                    if (!equal(state, newState)) {
                      return newState;
                    }
                  })
                }
                docCounts={facetCounts[facet]}
                initial={initial}
              />
            }
          />
        );
      })}
      {groupedFacets.map(facet => {
        return (
          <DrawerListEntry
            key={facet.parent + facet.facet}
            text={strings.searchDrawer.facets[facet.facet]}
            checked={filterFacets[facet.facet]}
            body={
              <GroupedFacetFilter
                filterKey={facet.facet}
                parentKey={facet.parent}
                setFilterChanged={setFilterChanged}
                facet={apiFacets[facet.facet]}
                parentFacet={apiFacets[facet.parent]}
                checked={filterFacets[facet.facet]}
                setChecked={facetCallbacks}
                docCounts={facetCounts[facet.parent]}
                initial={initial}
                parentChecked={filterFacets[facet.parent]}
              />
            }
          />
        );
      })}
    </Paper>
  );
}

DrawerContent.propTypes = {
  baskets: PropTypes.array.isRequired,
  filterConfig: PropTypes.object.isRequired,
  facetCounts: PropTypes.object.isRequired,
  filterCallbacks: PropTypes.object.isRequired,
  resetFilters: PropTypes.func.isRequired,
  initial: PropTypes.bool
};

function areEqual(prevProps, nextProps) {
  return equal(prevProps, nextProps);
}

export default React.memo(DrawerContent, areEqual);
