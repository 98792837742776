import { createContext } from "react";

/**
 * Global state for the login state.
 *
 * @type {React.Context<{loginStateDispatcher: loginStateDispatcher, loginState: string}>}
 */
export const LoginContext = createContext({
  loginState: "",
  loginStateDispatcher: () => {
  }
});
