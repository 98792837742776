import React, { useContext, useEffect, useState } from "react";
import { strings } from "../../localStrings";
import { Chip } from "@material-ui/core";
import equal from "deep-equal";
import PropTypes from "prop-types";
import axios from "axios";
import documentRequest from "../../requests/documentRequest";
import { requestError } from "../../utility/requestError";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Generates chips with the labels of the query.
 *
 * @param query
 * @param baskets
 * @param sharedBaskets
 * @returns {*}
 */
function BuildLabels({ query, baskets, sharedBaskets }) {
  const classes = useStyles();
  let labels = [];

  const colors = {
    like: "#0099ff",
    likeBasket: "#ff9900",
    basket: "#ff6600",
    facet: "#009933",
    mode: "#e0e0e0"
  };

  const loginContext = useContext(LoginContext);
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  if (query.mode === "context") {
    labels.push({ label: strings.context, type: "mode" });
  }

  const [likeLabels, setLikeLabels] = useState({});

  // Get the titles for the similar documents in the query.
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getLikeLabels = async () => {
      const state = {};
      for (let l of query.like) {
        await documentRequest(l.index, l.id, source)
          .then(response => {
            state[l.id] = response.data._source.title;
          })
          .catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));
      }
      setLikeLabels(state);
    };

    getLikeLabels();

    return () => {
      source.cancel();
    };
  }, [loginStateDispatcher, query.like, sessionExpiredDispatcher]);

  // Extract labels for similar documents.
  if (query.like) {
    query.like.map(like => {
      if (likeLabels[like.id] !== undefined) {
        labels.push({
          label: strings.similiarTo + ": " + likeLabels[like.id],
          type: "like"
        });
      }
    });
  }

  // Extract labels for similar baskets.
  if (query.likeBaskets) {
    query.likeBaskets.map(basket => {
      let basketLabel = basket.id;
      for (let b of baskets) {
        if (b._id === basket.id) {
          basketLabel = b.title;
          break;
        }
      }
      for (let b of sharedBaskets) {
        if (b._id === basket.id) {
          basketLabel = b.title;
          break;
        }
      }
      if (basketLabel !== basket.id) {
        labels.push({
          label: strings.similiarTo + ": " + basketLabel,
          type: "likeBasket"
        });
      }
    });
  }

  const filters = query.filters;

  // Extract labels for filters.
  if (filters) {
    if (filters.year) {
      let yearlabel = "";

      if (filters.year.from && filters.year.to) {
        yearlabel = filters.year.from + " - " + filters.year.to;
      } else if (filters.year.from) {
        yearlabel = strings.userSite.from + " " + filters.year.from;
      } else if (filters.year.to) {
        yearlabel = strings.userSite.to + " " + filters.year.to;
      }

      labels.push({ label: yearlabel, type: "year" });
    }

    if (filters.facets) {
      for (let key in filters.facets) {
        for (let i in filters.facets[key]) {
          labels.push({ label: filters.facets[key][i], type: "facet" });
        }
      }
    }

    if (filters.baskets && Array.isArray(filters.baskets)) {
      filters.baskets.map(basket => {
        let basketLabel = basket;
        for (let b of baskets) {
          if (b._id === basket) {
            basketLabel = b.title;
            break;
          }
        }
        for (let b of sharedBaskets) {
          if (b._id === basket) {
            basketLabel = b.title;
            break;
          }
        }
        if (basketLabel !== basket) {
          labels.push({ label: basketLabel, type: "basket" });
        }
      });
    }
  }

  return (
    <div>
      {labels.map(label => (
        <span key={label.label}>
          <Chip
            label={label.label}
            className={classes.chip}
            style={{ backgroundColor: colors[label.type] }}
          />{" "}
        </span>
      ))}
    </div>
  );
}

BuildLabels.propTypes = {
  query: PropTypes.object.isRequired,
  baskets: PropTypes.array,
  sharedBaskets: PropTypes.array
};

function areEqual(prevProps, nextProps) {
  return equal(prevProps, nextProps);
}

export default React.memo(BuildLabels, areEqual);
