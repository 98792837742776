import React, { useContext, useEffect, useState } from "react";
import basketDeleteRequest from "../../requests/basketDeleteRequest";
import basketChangeRequest from "../../requests/basketChangeRequest";
import basketContentSearchRequest from "../../requests/basketContentSearchRequest";
import basketRemoveDocumentRequest from "../../requests/basketRemoveDocumentRequest";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { strings } from "../../localStrings";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
import { requestError } from "../../utility/requestError";
import { queryCache, useMutation } from "react-query";
import BasketShareView from "./BasketShareView";
import BasketDocumentTable from "./BasketDocumentTable";
import BasketEntrySummary from "./BasketEntrySummary";
import PropTypes from "prop-types";
import { SignificantTerms } from "./SignificantTerms";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the contents of a single basket.
 *
 * @param basketId The id of the current basket.
 * @param basketTitle The title of the current basket.
 * @param setUpdatedBaskets Callback function to be called when the basket changed.
 * @param updatedBaskets True, if any basket of the current user was changed.
 * @param setUpdatedBasketName Callback function to be called if the basket has been renamed.
 * @param updatedBasketName The new name of the basket if it has been renamed.
 * @param setEditDialogOpen Callback function to open the edit dialog.
 * @param editDialogOpen If true, the edit dialog will be shown.
 * @param basketSharedWith
 * @returns {*}
 */
function BasketEntry({
  basketId,
  basketTitle,
  setUpdatedBaskets,
  updatedBaskets,
  setUpdatedBasketName,
  updatedBasketName,
  setEditDialogOpen,
  editDialogOpen,
  basketSharedWith
}) {
  const classes = useStyles();

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const [content, setContent] = useState([]);

  const handleEdit = () => {
    setEditDialogOpen(true);
  };

  const handleClose = () => {
    setEditDialogOpen(false);
  };

  // Delete the basket and invalidate the cache for the baskets query.
  const [deleteBaskeMutation] = useMutation(
    ({ basketId }) => {
      basketDeleteRequest(basketId);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  const handleDelete = () => {
    if (loginState) {
      deleteBaskeMutation({ basketId }).catch(
        requestError(sessionExpiredDispatcher, loginStateDispatcher)
      );
    }
    setUpdatedBaskets(true);
  };

  // Rename the basket and invalidate the cache for the baskets query.
  const [renameBaskeMutation] = useMutation(
    ({ basketId, updatedBasketName }) => {
      basketChangeRequest(basketId, updatedBasketName);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  const renameBasket = () => {
    setEditDialogOpen(false);

    if (loginState) {
      renameBaskeMutation({ basketId, updatedBasketName }).catch(
        requestError(sessionExpiredDispatcher, loginStateDispatcher)
      );
    }
    setUpdatedBaskets(true);
  };

  // Get the content of the basket on page load and anytime any basket (of the current user) changed.
  useEffect(() => {
    const fetchData = basketContentSearchRequest(
      basketId,
      sessionExpiredDispatcher,
      loginStateDispatcher
    );

    if (loginState) {
      fetchData().then(response => {
        setContent(response);
      });
    }
  }, [
    updatedBaskets,
    basketId,
    loginState,
    loginStateDispatcher,
    sessionExpiredDispatcher
  ]);

  // Remove documents from the basket and invalidate the cache for the baskets query.
  const [removeDocumentMutation] = useMutation(
    ({ basketId, resources }) => {
      basketRemoveDocumentRequest(basketId, resources);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  let counter = 0;
  const handleRemoveDocument = (documentIndex, documentId) => {
    let resources = {};
    resources[documentIndex] = [documentId];

    if (loginState && counter === 0) {
      removeDocumentMutation({ basketId, resources }).catch(
        requestError(sessionExpiredDispatcher, loginStateDispatcher)
      );
      counter++;
    }
    setUpdatedBaskets(true);
  };

  return (
    <Accordion key={basketId}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.expansionPanelHeading}
      >
        <BasketEntrySummary
          onRename={handleEdit}
          basketTitle={basketTitle}
          onDelete={handleDelete}
          basketId={basketId}
        />
      </AccordionSummary>
      {content.hits !== undefined && (
        <AccordionDetails className={classes.expansionPanelDetailsNoPadding}>
          <Grid container>
            <Grid item xs={12} className={classes.resultCard}>
              <BasketDocumentTable
                content={content}
                handleRemoveDocument={handleRemoveDocument}
              />
            </Grid>
            <Grid item xs={12} className={classes.card}>
              <SignificantTerms content={content} basketId={basketId} />
            </Grid>
            <Grid item xs={12} className={classes.card}>
              <BasketShareView
                basketId={basketId}
                sharedWith={basketSharedWith}
                setUpdatedBaskets={setUpdatedBaskets}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      )}
      <Dialog open={editDialogOpen} onClose={handleClose} fullWidth>
        <DialogTitle id="form-dialog-title">
          {strings.userSite.basketRename}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {strings.userSite.basketRenameDialog}
          </DialogContentText>
          <TextField
            autoFocus
            margin={"dense"}
            id={"basketRename"}
            fullWidth
            variant={"outlined"}
            label={strings.userSite.basketName}
            onChange={event => setUpdatedBasketName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {strings.cancel}
          </Button>
          <Button onClick={renameBasket} color="primary">
            {strings.rename}
          </Button>
        </DialogActions>
      </Dialog>
    </Accordion>
  );
}

BasketEntry.propTypes = {
  basketId: PropTypes.string.isRequired,
  basketTitle: PropTypes.string.isRequired,
  setUpdatedBaskets: PropTypes.func.isRequired,
  updatedBaskets: PropTypes.bool.isRequired,
  setUpdatedBasketName: PropTypes.func.isRequired,
  updatedBasketName: PropTypes.string.isRequired,
  setEditDialogOpen: PropTypes.func.isRequired,
  editDialogOpen: PropTypes.bool.isRequired,
  basketSharedWith: PropTypes.arrayOf(PropTypes.string)
};

export default BasketEntry;
