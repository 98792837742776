import { Grid, Button, Snackbar, Typography } from "@material-ui/core";
import { strings } from "../../localStrings";
import React, { useContext, useEffect, useState } from "react";
import basketRequest from "../../requests/basketRequest";
import basketCreateRequest from "../../requests/basketCreateRequest";
import SnackbarContentWrapper from "../SnackbarContentWrapper";
import BasketEntry from "./BasketEntry";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
import { requestError } from "../../utility/requestError";
import equal from "deep-equal";
import { LanguageContext } from "../../context/LanguageContext";
import { queryCache, useMutation, useQuery } from "react-query";
import { CreateBasketDialog } from "./CreateBasketDialog";
// import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the Baskets of the current user and provides methods to create, delete and edit them.
 *
 * @returns {JSX.Element}
 * @constructor
 */
function BasketView() {
  const classes = useStyles();

  const language = useContext(LanguageContext); // Rerender on language change

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const [baskets, setBaskets] = useState([]);
  const [updatedBaskets, setUpdatedBaskets] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newBasketName, setNewBasketName] = useState("");
  const [emptyNameOpen, setEmptyNameOpen] = useState(false);

  // Fetch all baskets for the current user. Query is cached.
  const basketInfo = useQuery("baskets", basketRequest, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    onError: requestError(sessionExpiredDispatcher, loginStateDispatcher),
    refetchOnMount: false
  });

  // Update the baskets state whenever a basket changes.
  useEffect(() => {
    if (
      basketInfo.data &&
      basketInfo.data.data &&
      !equal(baskets, basketInfo.data.data)
    ) {
      setBaskets(basketInfo.data.data);
    }
    setUpdatedBaskets(false);
  }, [updatedBaskets, basketInfo.data, baskets, setBaskets]);

  // Create a basket and invalidate the cache for the baskets query.
  const [createBasketMutation] = useMutation(
    ({ newBasketName, resources }) =>
      basketCreateRequest(newBasketName, resources),
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  const createBasket = () => {
    setDialogOpen(false);
    if (newBasketName === "") {
      setEmptyNameOpen(true);
    } else {
      if (loginState) {
        createBasketMutation({ newBasketName, undefined }).catch(
          requestError(sessionExpiredDispatcher, loginStateDispatcher)
        );
      }
      setUpdatedBaskets(true);
    }
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setEmptyNameOpen(false);
  };

  return (
    <div className={classes.paper}>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        className={classes.spacing}
      >
        {strings.userSite.baskets}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {baskets.map(value => {
            return (
              <BasketEntry
                basketId={value._id}
                basketTitle={value.title}
                key={value._id}
                setUpdatedBaskets={setUpdatedBaskets}
                updatedBaskets={updatedBaskets}
                setUpdatedBasketName={setNewBasketName}
                updatedBasketName={newBasketName}
                setEditDialogOpen={setEditDialogOpen}
                editDialogOpen={editDialogOpen}
                basketSharedWith={value.shared_read}
              />
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleOpen}
            className={classes.spacing}
            variant="contained"
            color={"primary"}
          >
            {strings.userSite.addBasket}
          </Button>
        </Grid>
      </Grid>
      <CreateBasketDialog
        open={dialogOpen}
        onClose={handleClose}
        onCreate={createBasket}
        onChange={event => setNewBasketName(event.target.value)}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={emptyNameOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={"error"}
          message={strings.userSite.basketNameEmptyText}
        />
      </Snackbar>
    </div>
  );
}

export default React.memo(BasketView);
