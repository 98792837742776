import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

const SAVE_QUERY_URL = APIBASE + `api/queries/save`;
const GET_RECENT_URL = APIBASE + `api/queries/recent`;
const GET_SAVED_URL = APIBASE + `api/queries/saved`;
const DELETE_QUERY_URL = APIBASE + `api/queries/`;

/**
 * Request to save a query.
 *
 * @param query_data The query to be saved.
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export async function SaveQuery(query_data) {
  return await axios.put(SAVE_QUERY_URL, JSON.stringify(query_data), {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "CORS"
  });
}

/**
 * Request for recent queries.
 *
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export async function GetRecentQueries() {
  return await axios.get(GET_RECENT_URL, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "CORS"
  });
}

/**
 * Request for saved queries.
 *
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export async function GetSavedQueries() {
  return await axios.get(GET_SAVED_URL, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "CORS"
  });
}

/**
 * Request to delete a saved query.
 *
 * @param query_id The id of the query to be deleted.
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export async function DeleteQuery(query_id) {
  return await axios.delete(DELETE_QUERY_URL + query_id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "CORS"
  });
}
