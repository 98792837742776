import React from "react";
import Downshift from "downshift";
import { appendClassName } from "@elastic/react-search-ui-views/lib/view-helpers";
import InputView from "./InputView";
import { strings } from "../../localStrings";
import PropTypes from "prop-types";
// import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the search box.
 *
 * @param properties Properties object from search-ui.
 * @param triggerSearch Callback function the executes the search.
 * @param mode The current search mode.
 * @param setMode Callback function to set the search mode.
 * @param setFilterConfig Callback function to set the search filters.
 * @param resetFilters Callback function to reset the serch filters.
 * @param saveQuery Callback function to save the current search query.
 * @param showSaveButton True, if the save query button should be shown.
 * @returns {JSX.Element}
 */
function SearchBox({
  properties,
  triggerSearch,
  mode,
  setMode,
  setFilterConfig,
  resetFilters,
  saveQuery,
  showSaveButton
}) {
  const {
    isFocused,
    inputProps = {},
    onChange,
    onSelectAutocomplete,
    value
  } = properties;
  const focusedClass = isFocused ? "focus" : "";

  const classes = useStyles();

  return (
    <Downshift
      inputValue={value}
      onChange={onSelectAutocomplete}
      onInputValueChange={newValue => {
        // To avoid over dispatching
        if (value === newValue) return;
        onChange(newValue);
      }}
      // Because when a selection is made, we don't really want to change
      // the inputValue. This is supposed to be a "controlled" value, and when
      // this happens we lose control of it.
      itemToString={() => value}
    >
      {downshiftProps => {
        const { closeMenu, getInputProps } = downshiftProps;
        return (
          <form
            onSubmit={e => {
              // Prevent the default behaviour of onSubmit to prevent a refresh of the site.
              e.preventDefault();
              e.stopPropagation();
              closeMenu();
              triggerSearch();
            }}
          >
            <InputView
              getInputProps={additionalProps => {
                const { className, ...rest } = additionalProps || {};
                return getInputProps({
                  placeholder: "Search your documents",
                  ...inputProps,
                  className: appendClassName("sui-search-box__text-input", [
                    inputProps.className,
                    className,
                    focusedClass
                  ]),
                  ...rest
                });
              }}
              getButtonProps={additionalProps => {
                const { className, ...rest } = additionalProps || {};
                return {
                  type: "submit",
                  value: strings.search,
                  className: appendClassName(
                    classes.searchButton,
                    "button sui-search-box__submit",
                    className
                  ),
                  ...rest
                };
              }}
              mode={mode}
              setMode={setMode}
              setFilterConfig={setFilterConfig}
              resetFilters={resetFilters}
              saveQuery={saveQuery}
              showSaveButton={showSaveButton}
              autocompleteSuggestions={properties.autocompletedSuggestions}
              onValueChange={onChange}
            />
          </form>
        );
      }}
    </Downshift>
  );
}

SearchBox.propTypes = {
  properties: PropTypes.object.isRequired,
  triggerSearch: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  setFilterConfig: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  saveQuery: PropTypes.func.isRequired,
  showSaveButton: PropTypes.bool.isRequired
};

export default SearchBox;
