import { createContext } from "react";

/**
 * Global state for the session.
 *
 * @type {React.Context<{notLoggedIn: boolean, notLoggedInDispatcher: notLoggedInDispatcher, loginSuccess: boolean, notFoundDispatcher: notFoundDispatcher, sessionExpiredDispatcher: sessionExpiredDispatcher, notFound: boolean, loginSuccessDispatcher: loginSuccessDispatcher}>}
 */
export const SessionContext = createContext({
  loginSuccess: false,
  loginSuccessDispatcher: () => {
  },
  notLoggedIn: false,
  notLoggedInDispatcher: () => {
  },
  sessionExpiredDispatcher: () => {
  },
  notFound: false,
  notFoundDispatcher: () => {
  }
});
