import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/baskets/";

/**
 * Request to change a basket title.
 *
 * @param basketId The id of the basket.
 * @param title The new title.
 * @returns {Promise<AxiosResponse<any>>}
 */
export default async function basketChangeRequest(basketId, title) {
  return await axios.put(
    APIURL + basketId,
    {
      title: title
    },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Bearer")
      }
    }
  );
}
