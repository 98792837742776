import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/token";

/**
 * Request to log into the application.
 * @param username The username.
 * @param password The password.
 * @returns {Promise<AxiosResponse<any>>}
 */
export default async function loginRequest(username, password) {
  return await axios.post(APIURL, {
    name: username,
    password: password
  });
}
