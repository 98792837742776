import { List, ListSubheader, Typography } from "@material-ui/core";
import { FilterEntry } from "./FilterEntry";
import React, { useEffect, useState } from "react";
import { strings } from "../../../localStrings";
import PropTypes from "prop-types";
import useStyles from "../../../useStyles";
import equal from "deep-equal";

/**
 * Renders the filter options for grouped filters.
 *
 * @param filterKey The key for the filter.
 * @param parentKey The key for the parent filter.
 * @param setFilterChanged Callback function to be called when the filter is changed.
 * @param checked Array with the selected filter values.
 * @param setChecked Callback function for checked.
 * @param docCounts Number of documents for each filter option.
 * @param initial True, if no search was executed.
 * @param parentFacet Object with all facet values of the parent filter, incuding the current subfilter.
 * @param parentChecked Array with selected filter values of the parent filter.
 * @returns {*}
 * @constructor
 */
function GroupedFacetFilter({
  filterKey,
  parentKey,
  setFilterChanged,
  checked,
  setChecked,
  docCounts = {},
  initial,
  parentFacet,
  parentChecked
}) {
  const classes = useStyles();

  let bucketDocCount = {};

  const [parentFacetNames, setParentFacetNames] = useState([]);
  const [facetMap, setFacetMap] = useState({});

  // Get the values of the parent facet filter.
  useEffect(() => {
    const names = [];
    for (let bucket of parentFacet.buckets) {
      names.push(bucket.key);
    }
    setParentFacetNames(names);
  }, [parentFacet.buckets]);

  // Get the facet values for each parent facet value.
  useEffect(() => {
    const facets = {};
    for (let bucket of parentFacet.buckets) {
      facets[bucket.key] = bucket[filterKey];
    }
    setFacetMap(facets);
  }, [filterKey, parentFacet.buckets]);

  // Get the documente counts and save them in bucketDocCount.
  if (!initial) {
    for (let bucket of parentFacet.buckets) {
      bucketDocCount[bucket.key] = [];
      for (let i = 0; i < bucket[filterKey].buckets.length; i++) {
        const key = bucket[filterKey].buckets[i].key;
        if (
          docCounts["subCounts"] !== undefined &&
          docCounts["subCounts"][bucket.key] !== undefined &&
          docCounts["subCounts"][bucket.key][filterKey] !== undefined
        ) {
          bucketDocCount[bucket.key][i] =
            docCounts["subCounts"][bucket.key][filterKey][key];
        }
      }
    }
  } else {
    for (let bucket of parentFacet.buckets) {
      bucketDocCount[bucket.key] = [];
      for (let i = 0; i < bucket[filterKey].buckets.length; i++) {
        bucketDocCount[bucket.key][i] = bucket[filterKey].buckets[i].doc_count;
      }
    }
  }

  // Clear filter if no option of the parent filter is selected.
  useEffect(() => {
    if (equal(parentChecked, []) && !equal(checked, [])) {
      setChecked(state => {
        const newState = { ...state };
        newState[filterKey] = [];
        if (!equal(state, newState)) {
          return newState;
        }
      });
    }
  }, [checked, filterKey, parentChecked, setChecked]);

  // Handle the selection of a filter.
  const handleToggle = (value, parent) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const parentCurrentIndex = parentChecked.indexOf(parent);
    const newParentChecked = [...parentChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      if (parentCurrentIndex === -1) {
        newParentChecked.push(parent);
      }
    } else if (parentCurrentIndex === -1) {
      newParentChecked.push(parent);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(state => {
      const newState = { ...state };
      newState[filterKey] = newChecked;
      newState[parentKey] = newParentChecked;
      if (!equal(state, newState)) {
        return newState;
      }
    });

    setFilterChanged(true);
  };

  // Check if the parent filter is selected.
  const getChecked = parentName => {
    if (parentChecked.includes(parentName)) {
      return checked;
    } else {
      return [];
    }
  };

  if (
    parentFacet.buckets.length === 0 ||
    (parentFacet.buckets.length === 1 &&
      (parentFacet.buckets[0].key === "<OTHER>" ||
        parentFacet.buckets[0] === ""))
  ) {
    return (
      <Typography className={classes.centeredText}>
        {strings.searchDrawer.noFilter}
      </Typography>
    );
  } else {
    return (
      <List className={classes.drawerContent} subheader={<li />}>
        {parentFacetNames
          .filter(value => {
            return facetMap[value].buckets.length !== 0;
          })
          .map(name => {
            return (
              <li key={"section-" + name}>
                <ul className={classes.ul}>
                  <ListSubheader>{name}</ListSubheader>
                  {facetMap[name].buckets.map((value, index) => (
                    <FilterEntry
                      key={value.key}
                      onToggle={key => handleToggle(key, name)}
                      docCount={bucketDocCount[name][index] || 0}
                      entry={value}
                      checked={getChecked(name)}
                    />
                  ))}
                </ul>
              </li>
            );
          })}
      </List>
    );
  }
}

GroupedFacetFilter.propTypes = {
  setFilterChanged: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired,
  docCounts: PropTypes.object.isRequired,
  initial: PropTypes.bool,
  parentFacet: PropTypes.object.isRequired,
  parentChecked: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterKey: PropTypes.string.isRequired,
  parentKey: PropTypes.string.isRequired
};

export default React.memo(GroupedFacetFilter);
