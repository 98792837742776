/**
 * Executes the given query.
 *
 * @param query
 * @param baseUrl
 */
export function executeQuery(query, baseUrl) {
  // Build the search url from the selected filters and search terms.
  const like = query.like === "" ? "" : query.like.map(e => e.id).join(",");
  const likeBaskets =
    query.likeBaskets === "" ? "" : query.likeBaskets.map(e => e.id).join(",");
  let url =
    "s/" +
    query.mode +
    "?q=" +
    query.term +
    "&size=n_" +
    query.pageSize +
    "_n" +
    (query.like.length > 0 ? "&like=" + like : "") +
    (query.likeBaskets.length > 0 ? "&likeBaskets=" + likeBaskets : "");

  let filterConfig = "";

  if (query.filters && Object.keys(query.filters).length > 0) {
    filterConfig += "&filters[0][field]=filter";

    if (query.filters.facets) {
      for (let key in query.filters.facets) {
        for (let index in query.filters.facets[key]) {
          filterConfig +=
            "&filters[0][values][0][facets][" +
            key +
            "][" +
            index +
            "]=" +
            query.filters.facets[key][index];
        }
      }
    }

    if (query.filters.baskets) {
      query.filters.baskets.forEach((id, index) => {
        filterConfig += "&filters[0][values][0][baskets][" + index + "]=" + id;
      });
    }

    if (query.filters.year) {
      if (query.filters.year.to) {
        filterConfig +=
          "&filters[0][values][0][year][to]=" + query.filters.year.to;
      }

      if (query.filters.year.from) {
        filterConfig +=
          "&filters[0][values][0][year][from]=" + query.filters.year.from;
      }
    }
  }

  // Update the url so that searchUI executes the search.
  const newLocation = baseUrl + "/" + url + filterConfig;
  if (window.location.href !== newLocation) {
    window.location.href = newLocation;
  }
}
